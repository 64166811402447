.container {
  align-items: center;
  background-color: #E6526C;
  border-radius: 6px;
  border: 0;
  display: inline-flex;
  justify-content: space-between;
  margin: 0;
  margin-right: 10px;
  outline: 0;
  padding: 4px 8px;
  margin-bottom: 10px;

  &:hover {
    background-color: #E6526C;
  }
}

.avatar {
  border-radius: 50%;
  border: 1px solid #fff;
  height: 25px;
  margin-right: 10px;
  width: 25px;
}

.name {
  color: #fff;
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
}

.remove {
  background: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  padding: 0;
  position: relative;
  text-align: center;
  top: -2px;
  width: 20px;
}

.removeButton {
  background-color: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  margin: 0;

  &:hover {
    background-color: #E6526C;
  }
}
