.section1 {
  display: flex;
  flex-direction: column;
}

.section1_background {
  background-color: rgba(255, 255, 255, 0.8);
  padding-bottom: 54px;
  padding-top: 110px;
}

.section1_text {
  color: #000;
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 54px;
}

.section1_title {
  color: var(--CRPURPLE);
  font-size: 60px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
}

.section1_cta {
  color: #000;
  font-size: 20px;
  line-height: 20px;
  margin-top: 54px;
}

.section1_description_container {
  position: relative;
}

.section1_description {
  color: #fff;
  font-size: 25px;
  line-height: 35px;
  margin: 100px 0;
  position: relative;
  text-align: center;
  z-index: 2;
}

.section2 {
  background-color: #fff;
  font-size: 26px;
  font-weight: 100;
  line-height: 32px;
}

.section3 {
  background-color: #f8f8f8;
  padding-top: 90px;

  h2 {
    font-size: 40px;
    font-weight: 400;
  }
}

.section4 {
  color: #fff;
  margin-top: 70px;
  padding-bottom: 160px;
  padding-top: 160px;
  position: relative;

  div {
    z-index: 1;
  }

  h2 {
    font-size: 32px;
    font-weight: 400;
  }

  p {
    color: #fff;
    font-size: 26px;
    margin-bottom: 65px;
    margin-top: 20px;
  }
}

.overlay {
  background-color: #707070;
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;
}

.calculator {
  background-color: #f0eaf4;
  font-size: 16px;
  line-height: 16px;
  padding: 21px;
  padding-bottom: 5px;

  tr {
    border-color: transparent;
  }

  .calculatorForm {
    background-color: #ffffff80;
    border-radius: 4px;
    border: 1px solid #707070;
    display: flex;
    font-size: 14px;
    margin-bottom: 10px;
    padding: 21px;
    text-align: left;

    h4 {
      font-size: 20px;
      margin-bottom: 0px;
    }

    .pledgeHourly {
      color: #484848;
      font-size: 18px;
      margin-top: 10px;
    }
  }

  .summary {
    line-height: 1.3rem;
    margin-bottom: 25px;
  }

  button {
    padding: 6px 40px;
  }

  .line {
    border-bottom: 1px solid #707070;
  }

  .lineThick {
    border-bottom: 2px solid #707070;
  }

  .tableRowKey {
    background-color: transparent;
    padding-right: 35px;
    text-align: right;
    width: 250px;
    font-weight: bold;
  }

  .tableRowValue {
    background-color: transparent;
    line-height: 1.3rem;
    text-align: left;
  }

  .tableRowIcon {
    background-color: transparent;
    line-height: 1.3rem;
  }
}

.adPreviewContainer {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  border-right: 0;
  border-left: 0;
}

.adPreviewFont {
  font-size: 0.85rem;
}

.terms {
  font-size: 12px;
  margin-bottom: 1rem;
}

.actionDate {
  font-size: 12px;
  margin-bottom: 0.5rem;
  text-align: center;
}

.section {
  padding-left: 2rem;
  padding-right: 2rem;
}

.successIconContainer {
  color: rgb(90, 167, 5);
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;

  svg {
    height: 50px;
    width: 50px;
  }
}

.modalBackground {
  background-color: var(--CRLGRAY);
}

.noteEdit {
  font-size: 0.75rem;
}

.messageSection {
  background-color: #fff;
  padding: 0.5rem 2rem;
  border-top: 1px solid var(--CRGRAY);
  border-bottom: 1px solid var(--CRGRAY);
}

.messageSectionEdit {
  background-color: var(--CRGRAY);
  padding: 0.5rem 2rem;
  border-bottom: 1px solid var(--CRGRAY);
}