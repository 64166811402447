.civic-datepicker {
  display: inline-block;
  text-align: left;
  margin-left: 0.3rem;
}

.CPsectionTitle {
  text-align: left;
  font-size: 1.5rem;
  font-weight: 200;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.datePickerWidth,
.datePickerWidth > div,
.datePickerWidth > div > div,
.datePickerWidth > div > div > input {
  width: 7.5rem;
  margin-right: 1rem;
}

.smallCenter {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  row-gap: 1rem;
  width: 14rem;
}

.smallCenter > * {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 auto 0.5rem;
}

.starRating {
  color: var(--CRPINK);
  font-size: 1.75rem;
}

.starIcon {
  margin: 0 5px;
}

.numberValue {
  color: var(--CRPURPLE);
  font-weight: 500;
}

@media (max-width: 576px) {
  .smallCenter {
    flex-flow: column wrap;
    margin: 0 auto;
  }
  .datePickerWidth > div > div > input {
    text-align: center;
  }
}
