.user-display {
  display: inline-flex;
  gap: 10px;
  justify-content: flex-start;
  font-size: 0.8rem;
  margin-top: 0.25rem;
  margin-right: 1.5rem;
  margin-bottom: 0.5rem;
}

.nickname {
  color: grey;
}

.profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}