.sortTableThead {
  border-bottom: 3px solid var(--CRMGRAY);
  border-top: 1px solid var(--CRMGRAY);
}

.sortTableBg {
  background-color: var(--CRLGRAY) !important;
}

.regular-font {
  font-size: 16px;
}
.smaller-font {
  font-size: 14px;
}