.inputGroup {
  border-color: var(--bs-border-color);
  border-radius: 6px;

  &:focus-within {
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem #86b7fe75;

    button,
    input {
      border-color: #86b7fe;
      box-shadow: none !important;
    }
  }
}

.inputGroupInvalid {
  border-color: var(--bs-danger-rgb);

  &:focus-within {
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
  }
}

.visibilityToggle {
  background-color: #fff;
  border-color: var(--bs-border-color);
  border-left: 0;
}

.visibilityToggleInvalid {
  border-color: var(--bs-form-invalid-border-color) !important;
}
