.container {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999;
}
.throbber,
.throbber div {
  box-sizing: border-box;
}
.throbber {
  display: inline-block;
  height: 80px;
  position: relative;
  width: 80px;
}
.throbber div {
  animation: throbber 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  background: #E6526C;
  display: inline-block;
  left: 8px;
  position: absolute;
  width: 16px;
}
.throbber div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.throbber div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.throbber div:nth-child(3) {
  left: 56px;
  animation-delay: 0s;
}
@keyframes throbber {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
