@import '~bootstrap/scss/bootstrap';

* {
  font-family: 'Roboto', sans-serif;
}

:root {
  --info: var(--CRPURPLE);

  --CRLPURPLE: #a06fc4;
  --CRPURPLE: #66308d;
  --CRDPURPLE: #38055d;

  --CRLPINK: #e88c9c;
  --CRPINK: #e6526c;
  --CRDPINK: #a83146;

  --CRBGGRAY: #f8f8f8;
  --CRLGRAY: #f1f1f1;
  --CRGRAY: #e2e2e2;
  --CRMGRAY: #9b9b9b;
  --CRDGRAY: #4a4a4a;

  --CRYELLOW: #f5a624;
  --CRGREEN: #61a913;
  --CRRED: #d11717;
  --CRDRED: #a71212;
}

#root {
  background-color: var(--CRBGGRAY);
}

.bg-info {
  background-color: var(--CRPURPLE) !important;
}

.verified-account {
  color: var(--CRGREEN);
}

// Remove browser default form autofill styles
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 600000s 0s,
    color 600000s 0s;
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: var(--CRPURPLE) !important;
}

.bg-light {
  background-color: var(--CRLGRAY) !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: var(--CRLGRAY) !important;
}

.btn-create {
  border-radius: 25px;
  background-color: var(--CRPURPLE);
  border-color: var(--CRPINK);
  border-width: 2.5px;
}
.btn-create:hover {
  color: #fff;
  background-color: var(--CRPINK);
  border-color: var(--CRPINK); /*set the color you want here*/
}

.btn-login {
  border-radius: 25px;
  background-color: var(--CRPURPLE);
  border-color: var(--CRPINK);
  width: 27%;
  border-width: 2.5px;
}
.btn-login:hover {
  color: #fff;
  background-color: var(--CRPINK);
  border-color: var(--CRPINK); /*set the color you want here*/
}

.logo {
  width: 35% !important;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.img-fluid {
  height: auto;
  display: block;
  @media (max-width: 575.98px) {
    width: 35%;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 578px) {
    width: 30%;
    margin-left: 1.2rem;
    margin-right: auto;
  }
  @media (min-width: 768px) {
    width: 40%;
  }
  @media (min-width: 992px) {
    width: 50%;
  }
  @media (min-width: 1200px) {
    width: 60%;
  }
}

.navbar-toggler {
  margin-right: 1.2rem;
}

.img-fluid2 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  width: 100%;
  object-fit: contain;
}
.img-fluid3 {
  max-width: 60%;
  height: auto;

  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.img-fluid4 {
  max-width: 20%;
  height: auto;

  display: block;
  margin-left: 5px;

  /*width: 90%;*/
}

// h6,
// .h6 {
//   /*left*/
//   font-size: 2rem;
//   display: block;
//   margin-left: auto;
//   margin-right: auto;
//   color: var(--CRPURPLE);
//   margin-left: 15%;
// }
// h5,
// .h5 {
//   font-size: 1rem;
//   margin-top: 15px;
//   margin-left: 80%;
//   margin-right: 5%;
// }
// h4,
// .h4 {
//   /*transform your community*/
//   font-size: 0.75rem;
//   margin-left: 23%;
// }
// h3,
// .h3 {
//   font-size: 1.75rem;
//   margin-left: 23%;
// }
// h2,
// .h2 {
//   margin-left: 23%;
// }

.appStoreIcons {
  margin-left: 23%;
}

.btn-primary.dropdown-toggle,
.btn-primary.dropdown-toggle:focus,
.show > .btn-primary.dropdown-toggle:focus {
  align-items: center;
  background-color: var(--CRPURPLE) !important;
  border-color: var(--CRPURPLE) !important;
  box-shadow: none !important;
  color: white !important;
  outline: none !important;
  font-size: 18px;
  padding: 11px;
  padding-left: 20px !important;

  &:hover {
    color: rgba(255, 255, 255, 0.7) !important;
  }
}

.form-group {
  margin-bottom: 1rem;

  label {
    margin-bottom: 0.5rem;
    margin-top: 0;
  }
}

.form-inline .form-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
  display: inline-block;
}

select.form-control {
  -webkit-appearance: revert;
  appearance: revert;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  margin-left: 25%;
}

.col-form-label-1 {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  margin-left: 20%;
}

.registration-email {
  width: 150%;
  margin-left: 10%;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-right: 30%;
}

.registration-b {
  width: 57%;
  margin-left: 5%;
  margin-top: 1px;
  margin-bottom: 1px;
}
.form-display {
  display: inline-block;
}

.centerit {
  display: inline;
  margin-left: 10%;
  margin-right: auto;
  margin-bottom: 2%;
}

.centerit2 {
  display: inline;
  margin-left: 25%;
  margin-right: auto;
  margin-bottom: 2%;
  margin-top: 2%;
}
.move {
  margin-left: 16%;
}
.outer {
  display: inline;
  /*margin-left: 90%;
  margin-right: 5%;*/
}

/*.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}*/

.left-col,
.right-col {
  float: left;
  height: 93%;
  margin: 2% 0;
  padding: 0 111px;
}

.pstyle {
  color: red;
}
.margin1 {
  margin-left: 10%;
}
.twobuttons {
  display: inline-block;
  text-align: center;
  margin-bottom: 2%;
}

// Place all the styles related to the home controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

body {
  background-color: var(--CRLGRAY);
}
h1 {
  font-size: 32px;
  margin: 20px 0 20px 0;
  font-weight: 300;
  color: var(--CRDGRAY);
}
h6 {
  color: black;
  font-size: 14px;
  line-height: 24px;
  padding-left: 0.5rem;
  font-weight: 400;
}
h6 i {
  color: lightgray;
  padding-right: 6px;
}
h4 {
  color: black;
  font-size: 21px;
  // overflow: hidden;
  padding-bottom: 10px;
  font-weight: 800;
}
p {
  color: black;
  font-size: 14px;
  font-weight: 300;
}
.editBtn {
  font-size: 20px;
  margin-top: 30px;
}
.viewBtn {
  font-size: 20px;
  margin-top: 30px;
}

.btn-primary {
  display: inline-block;
  background-color: var(--CRPURPLE);
  color: white !important;
  opacity: 1 !important;
  border: 2px solid var(--CRPURPLE) !important;
}
.btn-primary:hover,
.btn-primary:focus {
  background-color: var(--CRDPURPLE) !important;
  border: 2px solid var(--CRDPURPLE) !important;
}

.btn-primary:disabled,
.btn-secondary:disabled,
.btn-tertiary:disabled {
  color: #fff !important;
  background-color: var(--CRGRAY) !important;
  border: transparent !important;
  cursor: pointer;
}

.btn-secondary {
  // a:link {
  background-color: #fff !important;
  color: var(--CRPURPLE) !important;
  border: 2px solid var(--CRPURPLE) !important;
}

.btn-secondary:hover,
.btn-secondary:focus {
  background-color: var(--CRLPURPLE) !important;
  color: #fff !important;
}

.btn-tertiary {
  background-color: var(--CRPINK);
  color: #fff;
  border: 2px solid var(--CRPINK) !important;
}

.btn-tertiary:hover,
.btn-tertiary:focus {
  background-color: #fff !important;
  color: var(--CRPINK) !important;
  border: 2px solid var(--CRPINK) !important;
}

.btn-pink,
.btn-pink:hover,
.btn-pink:focus,
.btn-pink:active {
  background-color: var(--CRPINK) !important;
  color: #fff !important;
  border: 2px solid var(--CRPINK) !important;
}

.btn-danger {
  background-color: var(--CRRED);
  border: 2px solid var(--CRRED);
  color: #fff;
}

.btn-danger:hover,
.btn-danger:focus {
  background-color: var(--CRDRED);
  border: 2px solid var(--CRDRED);
}

.carousel-indicators {
  margin-bottom: 0.2rem;
}

.carousel-control-prev,
.carousel-control-next {
  margin: auto 0 !important;
  height: 2rem;
}

.navbar-top {
  background-color: var(--CRLGRAY) !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.navbar {
  background-color: var(--CRPURPLE) !important;
}

.navbar-expand-lg .navbar-collapse {
  flex-basis: 100%;
  justify-content: flex-end;
}

.navbar-nav {
  @media (max-width: 575.98px) {
    padding-right: 0px;
  }
  @media (min-width: 576px) {
    padding-right: 70px;
  }
}

.nav-link-top {
  color: var(--CRPINK) !important;
  font-size: 14px !important;
  padding: 10px !important;
  padding-left: 20px !important;
}

.navbar-dark .navbar-nav .nav-link {
  font-size: 18px !important;
  color: rgba(255, 255, 255, 1) !important;
  padding: 10px !important;
  padding-left: 20px !important;
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.7) !important;
}

.nav-dropdown .dropdown-menu {
  font-size: 18px !important;
  min-width: 0 !important;

  @media (min-width: 992px) {
    left: auto !important;
    right: 0 !important;
  }
}

.card {
  margin-bottom: 2rem;
  box-shadow: 5px 10px 9px 1px #ccc;
}
.card-img-top {
  height: 186px;
  object-fit: cover;
  object-position: top;
}
.card-img-top-topper {
  position: absolute;
  left: 50%;
  top: 78px;
  color: white;
  font-size: 30px;
}

.btn-checkbox {
  background-color: var(--CRPINK);
  border: var(--CRPINK);
  color: white;
  margin: 5px;

  &:active,
  &:hover,
  &.focus {
    background-color: var(--CRDPINK);
    border: var(--CRDPINK);
    color: white;
  }

  &.active {
    background-color: var(--CRDPINK) !important;
    border: var(--CRDPINK) !important;
    color: white !important;
  }
}

.btn-outline-danger {
  background-color: var(--CRLGRAY);
  color: var(--CRPINK);
  border-color: var(--CRPINK);
  margin-bottom: 1em;
  padding: 1em;
  font-size: 20;

  &.typeIndicator:hover {
    background-color: var(--CRLGRAY);
    color: var(--CRPINK);
    border-color: var(--CRPINK);
  }
}

.btn-block {
  display: block;
  width: 100%;
}
a.dropdown-item {
  text-decoration: none;
  color: var(--CRPINK) !important;
  background-color: #fff;
  padding: 4px 15px !important;
}
a.dropdown-item:hover,
a.dropdown-item:active {
  color: var(--CRPURPLE) !important;
  background-color: #fff;
}
.card-title {
  font-size: 21px;
}
.card-body {
  text-align: left;

  &.eventTypes {
    text-align: center;

    & .card-text {
      height: 60px;
    }
  }
}

.space-title {
  margin-top: 2rem;
}

.business-title-icon {
  margin-right: 1rem;
}

.chk-checkbox,
.chk-checkbox > input {
  border-color: var(--CRMGRAY);
}
.chk-checkbox:checked,
.chk-checkbox > input:checked,
.chk-checkbox > input:indeterminate {
  background-color: var(--CRPINK) !important;
  border-color: var(--CRPINK) !important;
}
.chk-checkbox:disabled {
  background-color: var(--CRMGRAY) !important;
}

.square {
  position: relative;
  max-width: 150px;
  margin-bottom: 2rem;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  .square-content {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.avatar-camera-content {
  position: absolute;
  right: -5px;
  bottom: -5px;
  z-index: 1000;
}

.avatar-camera-icon {
  color: var(--CRPINK);
  background-color: #fff;
  font-size: 16px;
  border: 1px solid var(--CRPINK);
  border-radius: 13px;
  padding: 5px;
}

.avatar-camera-icon:hover {
  color: #fff;
  background-color: var(--CRPINK);
  font-size: 16px;
  border: 1px solid var(--CRPINK);
  border-radius: 13px;
  padding: 5px;
}

.avatar-border {
  border: 1px solid var(--CRMGRAY);
}

.hidden {
  display: none;
}

.business-badge-container {
  display: flex;
  justify-content: flex-end;
}

.business-badge {
  align-self: flex-end;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.business-badge-icon {
  color: var(--CRMGRAY);
  font-size: xx-large;
}

.business-badge-label {
  color: var(--CRMGRAY);
  font-size: xx-large;
  padding-left: 10px;
}

.funding-icon {
  color: var(--CRMGRAY);
  font-size: xx-large;
}

.success-icon {
  color: var(--CRGREEN);
  font-size: xx-large;
}

.block-description {
  font-size: 1rem;
}

.info-circle {
  cursor: pointer;
  color: var(--CRPINK);
}

.info-circle-icon {
  margin-left: 5px;
}

.dashboard-sponsorship-block {
  height: 75px;
}

.event-pledge .accordion-button {
  background-color: var(--CRLGRAY) !important;
  color: #000 !important;
}

.pagination-button > .btn-primary:disabled {
  background-color: var(--CRGRAY) !important;
}

.terms {
  padding: 0;
  font-size: x-small;
}

.category-pill {
  background-color: white;
  color: var(--CRDGRAY);
  font-size: x-small;
  border: var(--CRPINK);
  border-style: solid;
  border-width: 0.125rem;
  margin: 0.313rem;
  padding: 0.175rem 0.25rem;
  border-radius: 0.25rem;
  width: fit-content;
}

.create-btn {
  text-align: right;
  justify-content: space-between;
}
.event-image-container {
  position: relative;
}

.clear {
  clear: both;
}

.event-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.event-image-topper {
  position: absolute;
  left: 40px;
  top: 40px;
  color: white;
  font-size: 20px;
}
.event-image-video {
  background-color: #000;
  height: 120px;
  width: 120px;
}

.postForm {
  margin-top: 4em;
  margin-bottom: 40px;

  &.postForm__edit {
    margin-top: 0;
  }
}
.marginBtns {
  margin-top: 16px;
  margin-bottom: 30px;
}
.btn-group-toggle {
  margin-bottom: 1em;
}
.btn-link {
  color: var(--CRPINK);
}
.btn-link:hover {
  color: var(--CRPURPLE);
}

.card-header {
  background-color: var(--CRLGRAY);
}
.postForm .card {
  margin-bottom: 0;
}
footer {
  height: 10em;
  background-color: var(--CRPINK);
  margin-top: 4em;
  padding: 1em;
  color: white;
  font-size: smaller;
}
footer a {
  color: white;
}
footer a:hover {
  color: var(--CRPURPLE);
  text-decoration: none;
}

.invalid,
.invalid-alt {
  box-shadow: 0 0 4px red;
}

.invalid + .alert.hide {
  display: block;
}

.invalid-alt ~ .alert.alternative.hide {
  display: block !important;
}

.delete-icon {
  background: white;
  border-radius: 13px;
  border: 3px solid var(--CRPINK);
  color: #000;
  cursor: pointer;
  font-size: 14px;
  height: 26px;
  line-height: 21px;
  position: absolute;
  right: -10px;
  text-align: center;
  top: -10px;
  width: 26px;
  z-index: 20;

  &:hover {
    background-color: var(--CRLGRAY);
    color: #000;
  }
}

#drop-area {
  border: 2px dashed #ccc;
  border-radius: 20px;
  font-family: sans-serif;
  padding: 20px;
  margin: 10px 0;

  &.highlight {
    border-color: purple;
  }

  p {
    margin-top: 0;
  }

  label {
    margin: 0;
  }
  #images {
    display: none;
  }
}

#gallery {
  margin-top: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
}

#gallery .image {
  width: 120px;
  height: 120px;
  margin-right: 10px;
  vertical-align: middle;
  display: inline-flex;
  margin-bottom: 20px;
}

.progress {
  margin-top: -50%;
  margin-left: 10px;
  margin-right: 10px;
}

.throbber {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}

.lds-facebook {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 25% auto;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: var(--CRPINK);
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.dropdown a {
  color: var(--CRPINK);
}
.dropdown a:hover {
  color: var(--CRPURPLE);
}
.badgeA {
  height: 60px;
  text-align: center;
}
.badgeG {
  height: 40px;
  text-align: center;
}
.call-to-action {
  font-size: medium;
  color: black;
  text-align: center;
}
.badge-success {
  color: white;
  background-color: gray;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 2;
  font-variant-caps: all-petite-caps;
  margin-right: 5px;
}

.badge-yellow {
  color: white;
  background-color: #f7a622;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 2;
  font-variant-caps: all-petite-caps;
  margin-right: 5px;
}

.badge-purple {
  color: white;
  background-color: var(--CRPURPLE);
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 2;
  font-variant-caps: all-petite-caps;
}

.max-height-list-modal {
  max-height: 300px;
}

/* update sizes for smaller devices */
@media (max-width: 992px) {
  h1 {
    font-size: 20px;
    margin: 0 0 20px 15px;
    font-weight: 300;
  }
  .editBtn {
    font-size: 16px;
    margin-top: 50px;
  }
  .viewBtn {
    margin-top: 1rem;
    font-size: 16px;
  }
}

.footer {
  .btn.btn-link {
    margin-top: 20px;
    font-size: medium;
    white-space: normal;

    &,
    &:visited,
    &:hover {
      color: white;
    }

    i {
      font-size: medium;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .mainfooter {
    background-color: var(--CRPURPLE);
    text-align: center;

    .social {
      white-space: nowrap;
    }
  }

  .allcaps {
    text-transform: uppercase;
  }

  .subfooter {
    padding-top: 15px;
    color: white;
    // height: 50px;
    background-color: var(--CRPINK);

    p {
      font-size: small;
      color: white;
    }

    .dev {
      display: none;
    }
  }
}

/* update sizes for smaller devices */
@media (max-width: 992px) {
  .footer .btn.btn-link,
  .footer .btn.btn-link:active,
  .footer .btn.btn-link:visited,
  .footer .btn.btn-link:hover {
    color: white;
    font-size: small;
    margin-top: 10px;
  }
  .footer .btn.btn-link:hover {
    text-decoration: underline;
  }
  .footer .subfooter a {
    font-size: x-small;
    color: white;
  }
}

#loginForm {
  h2.call-to-action {
    color: #66318c;
    text-transform: uppercase;
    text-align: center;
    font-size: 40px;
    margin: 30px 0;
  }

  h3 {
    font-size: 18px;
    font-weight: 300;
    text-align: left;
    text-transform: uppercase;
  }

  h4 {
    font-size: 30px;
    margin-bottom: 1rem;
  }

  .form-group {
    label {
      margin-bottom: 0.125rem;
    }
  }

  .instructions {
    padding-top: 5px;
    font-size: xx-small;
  }

  #error_explanation {
    h2 {
      font-size: 1em;
    }
  }

  .centered {
    text-align: center;
  }

  .spaced {
    margin: 20px 0;
  }

  .cr-link {
    color: var(--CRPINK);
  }
}

.invalid-accordion-header button,
.invalid-accordion-header h2 {
  color: #fff !important;
  background-color: red !important;
}

.accordion {
  .accordion-header {
    background-color: var(--CRLGRAY);
    padding-bottom: 0;
  }
  .accordion-button {
    background-color: var(--CRLGRAY);
    color: var(--CRPINK);

    &:hover {
      color: var(--CRPURPLE);
    }
  }
}

.card_title {
  color: black;
  font-size: large;
  font-weight: 500;
  line-height: 1.25rem;
  margin-bottom: 8px;
  margin-top: 8px;
  overflow-wrap: break-word;
  text-align: left;
}

.card-section-title {
  font-size: medium;
  font-weight: 400;
  margin-top: 1rem;
}

.card_subtitle {
  //dates and times
  color: black;
  font-size: small;
  font-weight: normal;
  line-height: 1.25rem;
  margin-bottom: 2px;

  a,
  a:link,
  a:visited {
    color: var(--CRPINK) !important;
    text-decoration: none;
  }

  svg {
    color: lightgray;
    padding-right: 6px;
    padding-left: 8px;
  }
}

.hashtag {
  margin-right: 5px;
  word-break: break-all;
}

.alert {
  display: block !important;

  button:hover {
    background-color: transparent;
  }
}

.block {
  height: 200px;
  .stat {
    font-size: 1.75rem;
  }
  .stat-label {
    font-size: 1rem;
  }
}

.th-center {
  background-color: var(--CRLGRAY) !important;
  text-align: center;
}

// Modify date picker component to match brand styling
.react-datepicker__day--selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: var(--CRPINK) !important;
  color: white;
}

.defaultText {
  color: var(--CRMGRAY);
}

.tooltip_primary > .tooltip-inner {
  background-color: var(--CRPINK) !important;
}

.tooltip_primary.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: var(--CRPINK);
}

.tooltip_primary.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: var(--CRPINK);
}

.tooltip_primary.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: var(--CRPINK);
}

.tooltip_primary.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: var(--CRPINK);
}

.tooltip_secondary > .tooltip-inner {
  background-color: var(--CRDGRAY) !important;
}

.tooltip_secondary.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: var(--CRDGRAY);
}

.tooltip_secondary.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: var(--CRDGRAY);
}

.tooltip_secondary.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: var(--CRDGRAY);
}

.tooltip_secondary.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: var(--CRDGRAY);
}

.grab-cursor {
  cursor: grab;
}

.badge-beta {
  background-color: var(--CRDPURPLE) !important;
  color: white !important;
  font-size: 0.675rem;
  font-weight: normal;
  margin-left: 5px;
}

.badge-container {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.text-pink {
  color: var(--CRPINK);
}

.smaller-font {
  font-size: 14px;
}

.font-size-0_7 {
  font-size: 0.7rem;
}

.font-size-0_8 {
  font-size: 0.8rem;
}

.terms {
  font-size: 12px;
}

// Editor
.wrapperEditor {
  padding: 0.2rem;
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  height: 300px;

  .rdw-link-modal {
    height: auto !important;
  }
}
.editorEditor {
  background-color: white;
  padding: 0.5rem;
  border: 1px solid var(--bs-border-color);
  height: 85% !important;

  .public-DraftStyleDefault-block {
    margin: 0 !important;
  }
}
.toolbarEditor {
  border: 1px solid var(--bs-border-color);
}
