:global {
  .mention {
    color: var(--CRPINK);
  }
}

.input {
  min-height: 250px;
  font-family: 'Roboto', sans-serif !important;

  p {
    font-size: 1rem !important;
    font-weight: 400;
  }

  ul li::marker {
    font-size: 1rem !important;
  }

  strong {
    font-weight: 700;
  }

  b {
    font-weight: 700;
  }

  li {
    font-size: 1rem !important;
    line-height: 1.2rem !important;
  }

  ul {
    font-size: 1rem !important;
  }

  ol {
    font-size: 1rem !important;
  }
}

.popoverContainer {
  position: relative;
}

.menuBar {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  position: relative;
  margin-bottom: 10px;
}

.isActive {
}

.tool {
  font-size: 12px !important;
}

.emojiPicker {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  overflow: auto;
  max-height: 300px;
  max-width: 200px;
  width: 200px;
}

.linkContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  overflow: auto;
  max-height: 300px;
  max-width: 200px;
  width: 200px;
}

.emojiPopover {
  transform: translate(-80px, 50px) !important;
  z-index: 800 !important;
}

.linkPopover {
  transform: translate(-80px, 50px) !important;
  z-index: 800 !important;
}

.emoji {
  font-size: 18px;
  cursor: pointer;
}
