.charityIndexPage {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.heading {
  font-size: 1.5rem;
}

.userCharitiesList {
  display: flex;
  padding: 1rem;
  background-color: #fff;
  border-radius: 6px;
  flex-direction: column;
  margin-bottom: 1rem;
  min-height: 300px;

  small {
    width: auto;
    text-align: center;
    margin-top: 100px;
  }
}

.userCharityRegistrationsList {
  background-color: #fff;
  border-radius: 6px;
  padding: 0.5rem 1rem;

  small {
    display: block;
    width: auto;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

.searchList {
  .searchBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;

    input {
      flex-grow: 1;
      margin-right: 1rem;
    }

    button {
      min-width: 100px;
    }
  }

  .listContainer {
    .list {
      display: flex;
      margin-top: 1rem;
      flex-direction: column;
      padding: 0;

      .row {
        align-items: center;
        border-top: 1px solid #ccc;
        display: flex;
        flex-direction: row;
        padding: 0.5rem 0;
        font-size: small;

        .status {
          border-radius: 4px;
          color: #fff;
          display: table;
          font-size: 10px;
          padding: 2px 4px;
          text-transform: uppercase;
        }

        .details {
          flex-grow: 1;
        }

        .actions {
          text-align: center;

          button {
            min-width: 100px;
          }
        }
      }
    }

    .pagination {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      margin-bottom: 2rem;
      align-items: center;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;

      button {
        margin: 0;
        max-width: 25px;

        &:focus {
          outline: none !important;
          box-shadow: none !important;
        }

        &.nextPrev {
          min-width: 110px;
          border: 0;

          &:not(:disabled) {
            color: #E6526C;
          }
        }
      }
    }
  }
}

.charityRegisterForm {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .registerForm {
    margin-top: 1rem;
    input {
      margin-bottom: 1rem;
      width: 100%;
    }
  }

  .listContainer {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 1rem 0;
    margin: 1rem 0;
  }

  .sectionHeading {
    font-size: 1.2rem;
    font-weight: 500;
  }

  .uploadsContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 1rem;

    .upload {
      background-color: #fff;
      border-radius: 6px;
      border: 1px dashed #ccc;
      height: 270px;
      padding: 1rem;
      width: 270px;
    }

    .uploaderContainer,
    .uploaderContainerDisabled {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 1rem;
      width: 100%;
      background-color: #fff;
      border-radius: 6px;
      border: 1px solid #ccc;
      padding: 5px;
      text-align: center;

      .uploaderInnerContainer {
        padding: 0.5rem;
        border: 1px dashed #ccc;
        border-radius: 6px;
      }

      .uploaderRequirement {
        font-weight: 700;
      }
      .uploaderTitle {
        font-weight: 700;
        margin-bottom: 0.5rem;
      }
      .uploaderDescription {
        line-height: 1.2;
        margin-bottom: 0.5rem;
      }
      .uploaderMediaRequirements {
        margin-bottom: 0.5rem;
      }
      .uploaderButton {
        background-color: #66308d;
        border-radius: 6px;
        color: #fff;
        padding: 3px 10px;

        &:hover {
          background-color: #4d1f6a;
        }
      }
    }

    .uploaderContainerDisabled {
      cursor: default;

      .uploaderButton {
        background-color: #574760;

        &:hover {
          background-color: #574760;
        }
      }
    }
  }

  .terms {
    font-size: 0.8rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .actionsContainer {
    margin-bottom: 1rem;
    margin-top: 1rem;
    text-align: right;

    button {
      min-width: 100px;
    }
  }
}

.uploaderColumn {
  width: 50%;
}

.uploaderPadding {
  height: 140px;
}

.badgeRow {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 1rem;
}

.removeButton {
  background-color: transparent;
  font-size: 10px;
  margin: 0;
  padding: 1px 6px;
  border-radius: 4px;

  svg {
    margin-right: 5px;
  }
}
