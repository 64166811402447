.login {
  text-align: center;
  margin: 2rem;
  font-size: small;
}

.terms {
  margin: 1rem;
  text-align: center;
  font-size: small;
}

.buttons {
  display: flex;
  gap: 1rem;
  margin: 1rem;
}

.button {
  flex-grow: 1;
}

.form {
  margin: 1rem;
}

.formGroup {
  margin-bottom: 1rem;
}

.slogan {
  text-transform: uppercase;
  margin-left: 1rem;
}

.title {
  margin: 1rem;
  margin-top: 0;
}

.header {
  border: 0;
}

.label {
  margin-bottom: 0;
}

.example {
  font-size: small;
}
