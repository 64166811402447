.mainContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: 600px;
  overflow: scroll;
}

.mainRatingAverage {
  padding-bottom: 0;
  margin-top: 10px;
  font-size: 48px !important;
  font-weight: bold;
}

.stars {
  color: var(--CRPINK);
  font-size: 16px;

  svg {
    margin: 0 4px;
  }
}

.expandOrCollapseIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 1px;
  font-size: 16px;
  margin-left: 20px;
}

.eventContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.eventTitleContainer {
  display: flex;
  width: 100%;
  cursor: pointer;
  background: var(--CRLGRAY);
  font-size: 16px;
  padding: 12px 15px;
  justify-content: space-between;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
}

.eventTitleActions {
  display: flex;
  align-items: center;
  gap: 6px;
}

.eventAverageRating {
  font-weight: bold;
  margin-left: 16px;
}

.ratingDetails {
  width: 100%;
  display: flex;
  font-size: 16px;
  padding: 0 20px;
  padding-right: 5px;
  flex-direction: column;
  border-bottom: 1px solid var(--CRLGRAY);
  padding-bottom: 15px;
}

.ratingMainContent {
  display: flex;
  font-size: 16px;
  padding: 10px;
  padding-bottom: 0;
  padding-right: 0;
  width: 100%;
  justify-content: space-between;
}

.ratingComment {
  color: var(--CRMGRAY);
  padding-left: 10px;
  font-size: 16px;
}

.ratingDetailsStars {
  color: var(--CRMGRAY);
  font-size: 16px;

  svg {
    margin: 0 4px;
  }
}

.ratingDetailsContainer {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  max-height: 0;
}

.ratingDetailsContainer.expanded {
  max-height: 500px;
  min-height: max-content;
}

.ratingDetailsContainer.collapsed {
  max-height: 0;
}

.expandOrCollapseIcon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 1px;
  font-size: 16px;
  margin-left: 20px;
}
