.card {
  margin-bottom: 2rem;
  box-shadow: 5px 10px 9px 1px #ccc;

  .card_badges {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .details {
    margin: 15px 8px;
  }

  .stats {
    margin: 30px 8px;
  }

  .card_link + .card_link {
    margin-left: 1.25rem;
  }

  .card_link {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 15px;

    svg {
      color: lightgray;
      padding-right: 6px;
    }
  }

  .badgeOutline {
    border-radius: 4px;
    border: 1px solid #66308d;
    color: #66308d;
    font-size: 14px;
    padding: 2px 4px;
    text-transform: uppercase;
  }
}
