.task {
  padding-top: 1em;
  padding-bottom: 1em;
  border-bottom: 2px solid #d8d8d8;

  label {
    padding-bottom: 0.25em;
  }

  .form-control.col-1 {
    width: 8.33333333%;
  }
  .form-control.col-2 {
    width: 16.66666667%;
  }
  .col-sub-3 {
    width: 23%;
  }
  .form-control.col-3 {
    width: 25%;
  }
  .form-control.col-4 {
    width: 41.66666667;
  }
  .form-control.col-6 {
    width: 50%;
  }

  .form-control:focus {
    z-index: 100;
    position: relative;
  }

  .values {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .task-position {
    color: #999;
    cursor: pointer;
  }

  .task-position,
  .task-actions {
    text-align: center;
    align-self: center;
  }

  .task-actions {
    flex-direction: row;
  }

  .task-title,
  .shift-start {
    border-radius: 0.25em 0 0 0.25em;
  }

  .task-leader1,
  .shift-end,
  .shift-volunteers {
    border-radius: 0;
  }

  .task-leader2,
  .shift-actions {
    border-radius: 0 0.25em 0.25em 0;
  }

  .task-leader-select {
    background-color: #ffffff;
  }

  .shifts {
    margin-left: 1em;

    label {
      padding-top: 0.5em;
    }

    .shift-start,
    .shift-end {
      line-height: 2.5;
    }

    .react-datepicker__tab-loop {
      width: 0;
    }
  }

  .invalid {
    background-color: #f00 !important;
  }
}

.eventVolunteersNeeded.invalid {
  background-color: #f00 !important;
}

.task-footer-buttons {
  padding: 30px 0;
}

.invalid-warning {
  color: #f00;
  margin-top: 1em;
}

.form-group {
  label {
    small {
      font-size: small;
    }
  }
}

.volunteerExtra {
  margin-left: 1em;

  .title {
    font-weight: bold;
    margin-bottom: 1em;
    margin-left: -1em;
  }

  label {
    align-items: center;
    display: flex;

    small {
      margin-left: 1em;
    }
  }

  .volunteer-shifts-title {
    font-weight: bold;
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
  }

  .volunteer-pill-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1em;
    margin-top: 0.5em;

    .task-button {
      align-items: center;
      color: #E6526C;
      cursor: pointer;
      display: flex;
      text-decoration: underline;
      font-weight: 400;
    }
    
    .invalid {
      color: #fff !important;
      background-color: red;
    }
  }

  .volunteer-pill {
    background-color: #4a4a4a;
    border-radius: 0.25em;
    color: #fff;
    display: inline-block;
    margin: 0 0.25em;
    padding: 0.25em 0.5em;

    &.empty {
      background-color: #dfe2e6;
    }

    &.invalid {
      background-color: red;
    }
  }
}
