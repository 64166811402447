.container {
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  border: 0;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  min-width: 150px;
  outline: 0;
  padding: 4px 8px;
  width: 100%;

  &:hover {
    background-color: #eee;
  }
}

.avatar {
  border-radius: 50%;
  border: 1px solid #555;
  background-color: #aaa;
  height: 35px;
  margin-right: 10px;
  width: 35px;
}

.name {
  color: #000;
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
  text-align: left;
}

.nickname {
  color: #E6526C;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
  text-align: left;
}

.selectedAdmins {
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;

  & > button {
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }
}

.radio {
  margin-right: 10px;
  border: 1px solid #aaa;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 0;
}

.radioActive {
  margin-right: 10px;
  border: 1px solid #E6526C;
  background-color: #E6526C;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 0;
}

.input {
  border: 1px solid #aaa;
  border-radius: 6px;
  padding: 4px 8px;
  width: 100%;
}
