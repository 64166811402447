.dropdownMenu {
  background: #ffffff;
  box-shadow: black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow: auto;
  position: relative;

  .dropdownItem {
    outline: none;
    display: flex;
    padding: 3px 10px;
    font-size: 14px;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
  }

  .name {
    font-weight: 500;
  }

  .nickName {
    color: var(--CRPINK);
  }

  .isSelected {
    background: var(--CRGRAY);
  }
}
