.groupcard__pending-badge {
  display: inline-block;
  font-size: x-small;
  border-radius: 2px;
  background-color: var(--CRLGRAY);
  color: var(--CRPINK);
  padding: 0.1rem 0.4rem;
}

.mobile-plugs {
  display: flex;
  margin: 10vh auto;
  flex-direction: column;
  max-width: 20rem;
  justify-content: center;
  gap: 20px;
}

.mobile-plugs > div {
  display: flex;
  gap: 20px;
}

@media (max-width: 576px) {
  .mobile-plugs > div {
    flex-direction: column;
  }
}
