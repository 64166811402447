.wrapper {
  @media (max-width: 575.98px) {
    height: 50rem;
    padding: 1rem;
  }
  @media (min-width: 576px) {
    height: 55rem;
    padding: 1rem 2rem;
  }
  @media (min-width: 768px) {
    height: 55rem;
    padding: 1rem 5rem;
  }
  @media (min-width: 992px) {
    height: 55rem;
    padding: 1rem 10rem;
  }
  display: flex;
  flex-direction: column;
}

.pdfDL {
  margin: 1rem auto;
}

.pdfFrame {
  height: 100%;
  width: 100%;
}
