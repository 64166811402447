.bg-content {
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px) grayscale(100%);
  -webkit-backdrop-filter: blur(10px) grayscale(100%);
  margin-top: -0.1em;
  display: flex;
  flex-direction: column;
}
.bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-grow: 1;
  display: flex;
}
.content {
  background-color: rgba(255, 255, 255, 1);
  padding: 40px 80px 15px 80px;
}
.logo-icon {
  text-align: center;
  padding: 20px;
}
.logo-icon img {
  border-radius: 15px;
}
.mrkSct {
  background-color: #f1f1f1;
  padding: 50px 10px;
  height: 100%;
}
.mrkmssg {
  text-align: center;
  font-size: 22px;
  width: 65%;
  margin: auto;
  padding-bottom: 15px;
}
h3 {
  text-align: center;
}
.badge-right {
  text-align: right;
}
.badge-right,
.badge-left {
  padding-top: 20px;
}
.grpPic {
  border-radius: 1em;
  box-shadow: -2px -2px 8px lightgray;
}
.groupStats {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: gray;
  font-weight: 400;
}
.groupOwner {
  font-size: 14px;
  text-align: center;
  color: black;
  font-weight: 300;
}
h2 {
  font-size: xx-large;
  text-align: center;
  padding-bottom: 6px;
  font-weight: 400;
}
.details p {
  font-size: large;
  padding-bottom: 25px;
  font-weight: normal;
  margin: 0px;
  overflow-wrap: break-word;
}
.participation {
  font-size: 18px;
  text-align: center;
}
.assocGrpList h6 {
  font-size: 18px;
  color: gray;
  margin-bottom: 26px;
}
.assocGrpList ul {
  list-style-type: none;
  padding: 0;
}
.assocGrpList li {
  font-size: 14px;
  padding-bottom: 10px;
}
.assocGrpPic {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 10px;
  border: 1px solid gray;
}
.categories {
  font-size: 10px;
  color: grey;
}

/* update sizes for smaller devices */
@media (max-width: 992px) {
  h2 {
    font-size:large;
    font-weight: 400;
  }
  .content {
    padding: 20px;
  }
  .details p {
    font-size: small;
    padding-bottom: 14px;
    margin: 0px;
    overflow-wrap: break-word;
  }
  .participation {
    font-size: 16px;
    text-align: center;
  }
  .groupStats,
  .groupOwner {
    font-size: 12px;
  }
  .assocGrpList h6 {
    font-size: 14px;
  }
  .assocGrpList li {
    font-size: 12px;
  }
  .assocGrpList ul {
    margin-bottom: 20px;
  }
  .assocGrpPic {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-right: 5px;
    border: 1px solid gray;
  }
}

.categoryList {
  display: flex;
  flex-wrap: wrap;
}
