.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card {
  width: 75%;
  min-width: fit-content;
}

.body {
  background-color: white;
  height: 70px;
  margin: 0;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
