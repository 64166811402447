.container {
  margin-top: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.thumbnail {
  display: inline-flex;
  height: 120px;
  margin-bottom: 20px;
  margin-right: 10px;
  position: relative;
  vertical-align: middle;
  width: 120px;
}

.imageContainer {
  position: relative;
}

.remove {
  background: white;
  border-radius: 13px;
  border: 3px solid #E6526C;
  color: #000;
  cursor: pointer;
  font-size: 14px;
  height: 26px;
  line-height: 21px;
  position: absolute;
  right: -2px;
  text-align: center;
  top: -10px;
  width: 26px;
  z-index: 20;

  &:hover {
    background-color: #f8f8f8;
    color: #000;
  }
}

.image {
  width: 120px;
  height: 120px;
  margin-right: 10px;
  vertical-align: middle;
  display: inline-flex;
  margin-bottom: 20px;
}

.progress {
  margin-top: -40%;
  margin-right: 10px;
}

.pdfContainer {
  width: 120px;
  height: 120px;
  margin-right: 10px;
  vertical-align: middle;
  display: inline-flex;
  margin-bottom: 20px;
  overflow: hidden;
}
