.inputContainer {
  display: flex;
  position: relative;
  width: 100%;
}

.clearButton {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #4a4a4a;
  min-width: 30px !important;
}
