.feed h1 {
  color: var(--CRDGRAY);
  @media (max-width: 575.98px) {
    font-size: 1.65rem;
    margin-bottom: 1.5rem;
  }
  @media (min-width: 578px) {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }
  @media (min-width: 992px) {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
}
.today {
  .card-img-top {
    object-fit: cover;
    object-position: top;
    @media (max-width: 575.98px) {
      height: 16rem;
    }
    @media (min-width: 576px) {
      height: 20rem;
    }
    @media (min-width: 768px) {
      height: 16rem;
    }
    @media (min-width: 992px) {
      height: 20rem;
      margin-bottom: 0.25rem;
    }
    @media (min-width: 1200px) {
      height: 26rem;
      margin-bottom: 0.25rem;
    }
    @media (min-width: 1400px) {
      height: 30rem;
      margin-bottom: 0.25rem;
    }
  }
  .card_body {
    @media (max-height: 575.98px) {
      height: 80px;
    }
    @media (min-height: 768px) {
      height: 100px;
      margin-bottom: 1rem;
    }
    @media (min-height: 992px) {
      height: 110px;
      margin-bottom: 1rem;
    }
    @media (min-height: 1200px) {
      height: 120px;
      margin-bottom: 1rem;
    }
    @media (min-height: 1400px) {
      height: 130px;
      margin-bottom: 1rem;
    }
  }
  .time {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.025rem;
    color: var(--CRPURPLE);
    padding: 0.125rem 0.35rem;
    margin-bottom: 0.85rem;
  }
  .card_title {
    color: var(--CRDGRAY);
    font-weight: 400;
    overflow-wrap: break-word;
    text-align: left;
    line-height: 1.5rem;
    padding-left: 0.35rem;
    @media (min-width: 768px) {
      margin-bottom: 4px;
      font-size: 1rem;
      margin-top: 4px;
    }
    @media (min-width: 1200px) {
      margin-bottom: 8px;
      font-size: 1.125rem;
      margin-top: 8px;
    }
    @media (min-width: 1400px) {
      margin-bottom: 8px;
      font-size: 1.375rem;
      margin-top: 8px;
    }
  }
  .location {
    color: var(--CRDGRAY);
    @media (max-width: 767.98px) {
      font-size: 0.675rem;
    }
    @media (min-width: 768px) {
      font-size: 0.75rem;
    }
    @media (min-width: 992px) {
      font-size: 0.875rem;
    }
  }
}

.upcoming {
  .card-img-top {
    object-fit: cover;
    object-position: top;
    @media (max-width: 575.98px) {
      height: 7rem;
    }
    @media (min-width: 576px) {
      height: 10rem;
      margin-bottom: 0.5rem;
    }
    @media (min-width: 992px) {
      height: 12rem;
      margin-bottom: 0.5rem;
    }
    @media (min-width: 1200px) {
      height: 12rem;
      margin-bottom: 0.5rem;
    }
  }
  .card_title {
    color: var(--CRDGRAY);
    font-weight: 400;
    text-align: left;
    @media (max-width: 575.98px) {
      font-size: 0.875rem;
      line-height: 1rem;
      padding-left: 0.125rem;
    }
    @media (min-width: 576px) {
      font-size: 0.975rem;
      line-height: 1.15rem;
    }
    @media (min-width: 768px) {
      font-size: 1.025rem;
      line-height: 1.25rem;
    }
    @media (min-width: 992px) {
      font-size: 1rem;
      line-height: 1.5rem;
      padding-left: 0.35rem;
    }
    @media (min-width: 1200px) {
      font-size: 1.125rem;
      line-height: 1.35rem;
    }
    @media (min-width: 1400px) {
      font-size: 1.175rem;
      line-height: 1.5rem;
    }
  }
  .card_body {
    padding-top: 0 !important;
    margin-bottom: 0.5rem;
    @media (max-width: 575.98px) {
      height: 60px;
    }
    @media (min-width: 576px) {
      height: 90px;
    }
    @media (min-width: 768px) {
      height: 140px;
    }
    @media (min-width: 992px) {
      height: 150px;
    }
    @media (min-width: 1200px) {
      height: 120px;
    }
    @media (min-width: 1400px) {
      height: 110px;
    }
  }
  .time {
    text-align: left;
    color: var(--CRPURPLE);
    font-weight: 500;
    @media (max-width: 575.98px) {
      letter-spacing: 0;
      font-size: 0.85rem;
      padding-left: 0.125rem;
    }
    @media (min-width: 576px) {
      letter-spacing: 0;
      font-size: 0.85rem;
    }
    @media (min-width: 768px) {
      letter-spacing: 0;
      font-size: 0.75rem;
    }
    @media (min-width: 992px) {
      letter-spacing: 0.025rem;
      font-size: 0.85rem;
      padding-left: 0.35rem;
    }
  }
}

.needs {
  .thumbnail {
    display: flex;
    padding-right: 0;
    border-right: 1px solid var(--CRGRAY);
    height: 130px;
    max-width: 125px;
  }
  .card_title {
    color: var(--CRDGRAY);
    padding: 5px 10px 5px 5px;
    margin: 0;
    width: 95%;
    @media (max-width: 575.98px) {
      font-size: 0.875rem;
      line-height: 1rem;
    }
    @media (min-width: 576px) {
      font-size: 1rem;
      line-height: 1.25rem;
    }
    @media (min-width: 768px) {
      font-size: 0.65rem;
      line-height: 0.875rem;
    }
    @media (min-width: 992px) {
      font-size: 0.75rem;
      line-height: 1rem;
    }
    @media (min-width: 1200px) {
      font-size: 1rem;
      line-height: 1.1rem;
    }
  }
  .card-img-top {
    height: 100%;
  }
  .card-body {
    display: -webkit-box;
    align-content: center;
    flex-wrap: wrap;
    max-height: 130px;
    overflow: hidden;
  }
}

.groups {
  .events {
    .thumbnail {
      display: flex;
      flex-shrink: 0;
      flex-grow: 0;
      padding-right: 0;
      border-right: 1px solid var(--CRGRAY);
      @media (max-width: 575.98px) {
        flex-basis: 50%;
      }
      @media (min-width: 576px) {
        flex-basis: 30%;
      }
      @media (min-width: 768px) {
        width: 45%;
      }
      @media (min-width: 1200px) {
        flex-basis: 40%;
      }
    }
    .time {
      font-weight: 400;
      color: var(--CRPURPLE);
      text-align: left;
      background-color: var(--CRLGRAY);
      padding: 0.125rem 0.35rem;
      @media (max-width: 575.98px) {
        font-size: 0.65rem;
        margin-bottom: 0.5rem;
        letter-spacing: 0.025rem;
        line-height: 1rem;
      }
      @media (min-width: 576px) {
        font-size: 0.75rem;
        margin-bottom: 0.45rem;
        letter-spacing: 0.025rem;
      }
      @media (min-width: 768px) {
        font-size: 0.75rem;
        margin-bottom: 0.85rem;
        font-weight: 400;
        letter-spacing: 0.025rem;
      }
      @media (min-width: 992px) {
        font-size: 0.825rem;
        margin-bottom: 0.85rem;
        letter-spacing: 0.025rem;
      }
      @media (min-width: 1200px) {
        font-size: 0.875rem;
        margin-bottom: 0.85rem;
        letter-spacing: 0.025rem;
      }
    }
    .date {
      font-weight: 400;
      color: var(--CRPURPLE);
      @media (max-width: 575.98px) {
        font-size: 0.725rem;
        line-height: 0.95rem;
        margin-bottom: 0.25rem;
        letter-spacing: 0.025rem;
        padding-left: 0.35rem;
      }
      @media (min-width: 576px) {
        font-size: 0.825rem;
        line-height: 0.95rem;
        margin-bottom: 0.5rem;
        letter-spacing: 0.025rem;
        padding-left: 0.35rem;
      }
      @media (min-width: 768px) {
        font-size: 0.85rem;
        line-height: 1rem;
        margin-bottom: 0.5rem;
        letter-spacing: 0.025rem;
        padding-left: 0.35rem;
      }
      @media (min-width: 992px) {
        font-size: 0.925rem;
        line-height: 1.25rem;
        margin-bottom: 0.25rem;
        letter-spacing: 0.025rem;
        padding-left: 0.35rem;
      }
    }
    .card_title {
      color: var(--CRDGRAY);
      padding-left: 0.35rem;
      @media (max-width: 575.98px) {
        font-size: 0.875rem;
      }
      @media (min-width: 576px) {
        font-size: 1.2rem;
        line-height: 1.45rem;
      }
      @media (min-width: 768px) {
        font-size: 1rem;
        line-height: 1.25rem;
      }
      @media (min-width: 992px) {
        font-size: 1.1rem;
        line-height: 1.5rem;
      }
    }
    .card-img-top {
      height: 100%;
    }
    .text {
      padding-left: 0;
    }
  }
  .list {
    @media (max-width: 575.98px) {
      .thumbnail {
        width: 100%;
        height: 240px;
      }
      .thumbnail::before {
        width: 100%;
        height: 240px;
      }
      p {
        font-size: 0.75rem;
        line-height: 0.95rem;
      }
      h4 {
        font-size: 1rem;
        font-weight: normal;
        padding-top: 1rem;
        color: var(--CRDGRAY);
        letter-spacing: 0.025rem;
        margin-bottom: 0.125rem;
      }
    }
    @media (min-width: 576px) {
      .thumbnail {
        width: 100%;
        height: 140px;
      }
      .thumbnail::before {
        width: 100%;
        height: 140px;
      }
      p {
        font-size: 0.75rem;
        line-height: 0.95rem;
      }
      h4 {
        font-size: 1rem;
        font-weight: normal;
        padding-top: 1rem;
        color: var(--CRDGRAY);
        letter-spacing: 0.025rem;
        margin-bottom: 0.125rem;
      }
    }
    @media (min-width: 768px) {
      .thumbnail {
        width: 100%;
        height: 110px;
      }
      .thumbnail::before {
        width: 100%;
        height: 110px;
      }
      p {
        font-size: 0.75rem;
        line-height: 0.85rem;
      }
    }
    @media (min-width: 1200px) {
      .thumbnail {
        width: 110px;
        height: 110px;
      }
      .thumbnail::before {
        width: 110px;
        height: 110px;
      }
      p {
        font-size: 0.65rem;
        line-height: 0.75rem;
      }
    }
    .thumbnail {
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 5px;
    }
    .thumbnail::before {
      content: ' ';
      background-image: linear-gradient(to bottom, var(--CRMGRAY), #000);
      opacity: 0.5;
      position: absolute;
      border-radius: 5px;
    }
    p {
      color: #fff;
      margin: 0;
      letter-spacing: 0.035rem;
    }
  }
}

.connections {
  .card-img-top {
    object-fit: cover;
    object-position: top;
    @media (max-width: 575.98px) {
      height: 225px;
    }
    @media (min-width: 576px) {
      height: 20rem;
    }
    @media (min-width: 768px) {
      height: 125px;
    }
    @media (min-width: 1200px) {
      height: 175px;
    }
  }
  .card_title {
    color: var(--CRDGRAY);
    font-weight: 400;
    overflow-wrap: break-word;
    text-align: left;
    margin-bottom: 8px;
    margin-top: 8px;
    @media (max-width: 575.98px) {
      font-size: 0.9rem;
      line-height: 1.12rem;
    }
    @media (min-width: 576px) {
      font-size: 1rem;
      line-height: 1.25rem;
    }
    @media (min-width: 768px) {
      font-size: 0.85rem;
      line-height: 1.12rem;
    }
    @media (min-width: 992px) {
      font-size: 1rem;
      line-height: 1.18rem;
    }
    @media (min-width: 1200px) {
      font-size: 1.025rem;
      line-height: 1.25rem;
    }
  }
  .time {
    font-weight: 400;
    letter-spacing: 0.025rem;
    margin-bottom: 0.5rem;
    color: var(--CRPURPLE);
    @media (max-width: 575.98px) {
      font-size: 0.8rem;
      line-height: 1.12rem;
    }
    @media (min-width: 576px) {
      font-size: 0.9rem;
      line-height: 1.25rem;
    }
    @media (min-width: 768px) {
      font-size: 0.8rem;
      line-height: 1.12rem;
    }
    @media (min-width: 992px) {
      font-size: 0.85rem;
      line-height: 1.18rem;
    }
    @media (min-width: 1200px) {
      font-size: 0.9rem;
      line-height: 1.25rem;
    }
  }
  .link-more {
    font-size: 0.725rem;
    color: var(--CRPINK);
    @media (max-width: 575.98px) {
      font-size: 0.7rem;
      line-height: 1.12rem;
    }
    @media (min-width: 768px) {
      font-size: 0.75rem;
      line-height: 1.12rem;
    }
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    line-height: 1rem;
    color: var(--CRDGRAY);
    @media (max-width: 575.98px) {
      font-size: 0.8rem;
      line-height: 1.12rem;
    }
    @media (min-width: 568px) {
      font-size: 0.8rem;
      line-height: 1.12rem;
    }
    @media (min-width: 768px) {
      font-size: 0.75rem;
      line-height: 1.2rem;
    }
  }
  hr {
    margin: 0.5rem 0;
  }
}
.rowSpacer {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
}

h6 {
  padding-left: 0.5rem;
}

// for the carousel dot navigation
.react-multi-carousel-dot button {
  border-color: var(--CRPURPLE);
  border-width: 1px;
}

.react-multi-carousel-dot--active button {
  background-color: var(--CRPURPLE);
}

.react-multi-carousel-list {
  padding-bottom: 0.8rem;
}
