.container {
  border: 1px solid #9b9b9b;
  border-radius: 5px;
  background-color: white;
  padding: 5px;
  display: flex;
  flex-direction: row;
  height: 100px;
}
.logo {
  width: 75px;
  height: 75px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.messageContent {
  height: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: 12px;
  -webkit-line-clamp: 4;
  line-clamp: 5;
}
.content {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  // flex-shrink: 0;
}
.name {
  font-weight: bold;
}
.message {
  word-break: keep-all;
}
.footer {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  height: 20%;
  font-size: 12px;
}
.ref {
  color: #9b9b9b;
}

/* update sizes for smaller devices */
@media (max-width: 991.98px) {
  .logo {
    width: 50px;
    height: 50px;
  }
  .content {
    margin: 0 7px;
  }
  .footer {
    font-size: 12px;
  }
}

/* update sizes for smaller devices */
@media (max-width: 767.98px) {
  .logo {
    width: 45px;
    height: 45px;
  }
  .content {
    margin: 0 5px;
  }
  .messageContent {
    font-size: 16px;
  }
  .footer {
    font-size: 10px;
  }
}

@media print {
  .messageContent {
    font-size: 12px;
    -webkit-line-clamp: 4;
    line-clamp: 5;
  }
}